import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./main.css";
import "./components/Loaders/loader.css";
import "./views/Home/home.css";
import "./views/Docs/docs.css";
import "./components/WalletInteractionMenu/interactionmenu.css";

import MainLoader from "./components/Loaders/MainLoader";

// Sentry.init({
//   dsn: "https://c03b4e35470143d1ba8a4bf5065569d2@o4504923617099776.ingest.sentry.io/4504923624833025",
//   tracesSampleRate: 0,
//   ignoreErrors: [
//     /Cannot.*read/i,
//     /invalid.*BigNumber/i
//   ],
// });

const App = React.lazy(() => import("./App"));


ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <React.Suspense fallback={<MainLoader />}>
      <App />
    </React.Suspense>
  </React.StrictMode>
);
