import MainLoaderSpinner from "../../../images/MainLoader.gif";

const MainLoader = () => {
  return (
    <div className="main-loader">
        <img src={MainLoaderSpinner} style={{
            width: "100px",
            height: "100px",
        }} alt="logo" />
  </div>
  );
};

export default MainLoader;
